import ForgotPassword from "../pages/guest/ForgotPassword"
import ResetPassword from "../pages/guest/ResetPassword"
import SetPassword from "../pages/guest/SetPassword"
import Layout from "../components/guest/Layout"
import PropAuth from "../pages/guest/PropAuth"
import UsreAuth from "../pages/guest/UsreAuth"
import Support from "../pages/guest/Support"
import Product from "../pages/guest/Product"
import Login from "../pages/guest/Login"
import { Route } from "react-router-dom"
import Home from "../pages/guest/Home"
import FAQs from "../pages/guest/FAQs"

const GuestRoutes = (
  <Route path="/" element={<Layout />}>
    <Route path="" element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="/faqs" element={<FAQs />} />
    <Route path="login" element={<Login />} />
    <Route path="/support" element={<Support />} />
    <Route path="/product" element={<Product />} />
    <Route path="/set-password" element={<SetPassword />} />
    <Route path="/user-auth/:userId" element={<UsreAuth />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/property-auth/:propId" element={<PropAuth />} />
  </Route>
)

export default GuestRoutes