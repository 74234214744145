import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Handle create users request
export const handleCreateUsersReq = createAsyncThunk("handleCreateUsersReq", (data, { dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/user/create`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      // rejectWithValue(err)
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all users
export const getAllUsersReq = createAsyncThunk("getAllUsersReq", async (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = await axios.get(`${BASEURI}/admin/user/get-all`, {
      params,
      headers: adminHeaders()
    })

    if (response.data?.success) {
      return response.data?.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Get single user request
export const getSingleUserReq = createAsyncThunk("getSingleUserReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/user/get/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data?.user
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Delete user request
export const handleDeleteUserReq = createAsyncThunk("deleteUserReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.delete(`${BASEURI}/admin/user/delete/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      } else {
        dispatch(toastSuccess(res?.data?.error))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle updated user request
export const handleUpdateUserReq = createAsyncThunk("handleUpdateUserReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/user/update/${data?.id}`, data?.formData, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  user: {},
  singleUser: {},
}
const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle create users request
    builder.addCase(handleCreateUsersReq.pending, (state) => {
      state.user.loadingCreate = true
    })
    builder.addCase(handleCreateUsersReq.fulfilled, (state) => {
      delete state?.user?.loadingCreate
    })
    builder.addCase(handleCreateUsersReq.rejected, (state) => {
      delete state?.user?.loadingCreate
    })

    // Handle updated user request
    builder.addCase(handleUpdateUserReq.pending, (state) => {
      state.user.loadingUpdate = true
    })
    builder.addCase(handleUpdateUserReq.fulfilled, (state) => {
      delete state?.user?.loadingUpdate
    })
    builder.addCase(handleUpdateUserReq.rejected, (state) => {
      delete state?.user?.loadingUpdate
    })

    // Get all users request
    builder.addCase(getAllUsersReq.pending, (state) => {
      state.user.loadingAll = true
    })
    builder.addCase(getAllUsersReq.fulfilled, (state) => {
      delete state?.user?.loadingAll
    })
    builder.addCase(getAllUsersReq.rejected, (state) => {
      delete state?.user?.loadingAll
    })

    // Delete user request
    builder.addCase(handleDeleteUserReq.pending, (state) => {
      state.user.loadingDelete = true
    })
    builder.addCase(handleDeleteUserReq.fulfilled, (state) => {
      delete state?.user?.loadingDelete
    })
    builder.addCase(handleDeleteUserReq.rejected, (state) => {
      delete state?.user?.loadingDelete
    })

    // Handle get user request
    builder.addCase(getSingleUserReq.pending, (state) => {
      state.singleUser.loading = true
    })
    builder.addCase(getSingleUserReq.fulfilled, (state, { payload }) => {
      delete state?.singleUser?.loading
      state.singleUser = payload || {}
    })
    builder.addCase(getSingleUserReq.rejected, (state) => {
      delete state?.singleUser?.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default users.reducer