import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Get all requests
export const getAllRequests = createAsyncThunk("getAllRequestsByUser", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
    status: data?.status || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/request/get/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all requests by user
export const getAllRequestsByUser = createAsyncThunk("getAllRequestsByUser", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null
  }
  try {
    const response = axios.get(`${BASEURI}/admin/request/get/${data?.id}`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get approve/reject request
export const approveRequests = createAsyncThunk("approveRequests", ({ id, status }, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/update-request/${id}`, { status }, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get single request
export const getSingleRequest = createAsyncThunk("getSingleRequest", (id, { dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/request/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.request
      }
    }).catch((err) => {
      console.log(err)
      if (err?.response?.data?.error) {
        return dispatch(toastError(err?.response?.data?.error))
      } else {
        return dispatch(toastError("Something went wrong!"))
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  request: {}
}
const request = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all requests by user
    builder.addCase(getAllRequestsByUser.pending, (state) => {
      state.request.loadingAll = true
    })
    builder.addCase(getAllRequestsByUser.fulfilled, (state) => {
      delete state?.request?.loadingAll
    })
    builder.addCase(getAllRequestsByUser.rejected, (state) => {
      delete state?.request?.loadingAll
    })

    // Get approve/reject request
    builder.addCase(approveRequests.pending, (state) => {
      state.request.sLoading = true
    })
    builder.addCase(approveRequests.fulfilled, (state) => {
      delete state.request.sLoading
    })
    builder.addCase(approveRequests.rejected, (state) => {
      delete state.request.sLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default request.reducer