import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI, checkTokenErr, headers } from "../../utils/helper"
import axios from "axios"

// Upload request to admin
export const handleSaveAdminReq = createAsyncThunk("handleSaveAdminReq", async (data, { dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/user/chat/create-request`, data, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess("Request submited!"))
        return res.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        return dispatch(toastError(err?.response?.data?.error))
      } else {
        return dispatch(toastError("Something went wrong!"))
      }
      // return rejectWithValue(err?.response?.data?.error || "Unknown error occurred")
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all requests
export const getAllRequests = createAsyncThunk("handleSaveAdminReq", (_, { dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/user/all-requests`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      checkTokenErr(err, dispatch)
      if (err?.response?.data?.error) {
        return dispatch(toastError(err?.response?.data?.error))
      } else {
        return dispatch(toastError("Something went wrong!"))
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get single request
export const getSingleRequest = createAsyncThunk("getSingleRequest", (id, { dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/user/request/${id}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.request
      }
    }).catch((err) => {
      console.log(err)
      if (err?.response?.data?.error) {
        return dispatch(toastError(err?.response?.data?.error))
      } else {
        return dispatch(toastError("Something went wrong!"))
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  request: {},
  singleRequest: {}
}
const request = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Upload request to admin
    builder.addCase(handleSaveAdminReq.pending, (state) => {
      state.request.loading = true
    })
    builder.addCase(handleSaveAdminReq.fulfilled, (state) => {
      delete state.request.loading
    })
    builder.addCase(handleSaveAdminReq.rejected, (state) => {
      delete state.request.loading
    })
    // Get single request
    builder.addCase(getSingleRequest.pending, (state) => {
      state.singleRequest.loading = true
    })
    builder.addCase(getSingleRequest.fulfilled, (state, { payload }) => {
      delete state.singleRequest.loading
      state.singleRequest = payload
    })
    builder.addCase(getSingleRequest.rejected, (state) => {
      delete state.singleRequest.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default request.reducer