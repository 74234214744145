import MuiIcons from "../../assets/js/muiIcons";

export const menus = [
  {
    name: "dashboard",
    path: "/admin/dashboard",
    icon: <MuiIcons.DashboardRounded sx={{ fontSize: "22px !important" }} />,
  },
  {
    name: "admin",
    path: "/admin/sub-admins",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "22px !important" }} />,
  },
  {
    name: "properties",
    path: "/admin/properties",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "22px !important" }} />,
  },
  {
    name: "users",
    path: "/admin/all-users",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "22px !important" }} />,
  },
  // {
  //   name: "requests",
  //   path: "/admin/all-request",
  //   icon: <MuiIcons.ForumRounded sx={{ fontSize: "22px !important" }} />,
  // },
  {
    name: "contacts",
    path: "/admin/all-contacts",
    icon: <MuiIcons.AnnouncementRounded sx={{ fontSize: "22px !important" }} />,
  },
]