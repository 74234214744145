import { showDialog, showDialogWithData, toastSuccess } from "../../../redux/guestSlices/other.slice"
import { getAllPropertiesReq } from "../../../redux/adminSlices/property.slice"
import { propertiesTableHeader } from "../../../constant/admin/Tables"
import SearchInput from "../../../components/common/SearchInput"
import { Fragment, useEffect, useRef, useState } from "react"
import { Link, useOutletContext } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"
import { ORIGINURI } from "../../../utils/helper"

import {
  Box, Button, IconButton, Pagination, Skeleton,
  Stack, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@mui/material"

function AllProperties() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const { sidebarActive } = useOutletContext()
  const { refresh } = useSelector(state => state.otherSlice)
  const { property } = useSelector(state => state.propertySlice)
  let searchIntervel

  // Search properties
  const handleSearch = () => {
    clearInterval(searchIntervel)
    searchIntervel = setTimeout(() => {
      handleGetAll(null, searchRef?.current?.value)
    }, 1500)
  }

  // Get all properties
  const handleGetAll = (page = null, search = null) => {
    dispatch(getAllPropertiesReq({
      page,
      search
    })).unwrap().then((res) => {
      setData(res)
    }).catch(() => { })
  }

  // Handle create property
  const handleCreate = () => {
    dispatch(showDialog("CreateProperty"))
  }

  // Handle delete property 
  const handleDelete = (propId) => {
    dispatch(showDialogWithData({ type: "DeleteProperty", data: { propId } }))
  }

  // Handle copy link 
  const handleCopyLink = (id) => {
    const newUrl = `${ORIGINURI}/property-auth/${id}`
    if (id) {
      navigator.clipboard.writeText(newUrl)
      dispatch(toastSuccess("Link copied!"))
    }
  }

  useEffect(() => {
    handleGetAll()

    // eslint-disable-next-line
  }, [refresh])

  return (
    <Fragment>
      <Header sidebarActive={sidebarActive}>
        <Box sx={{ maxWidth: "250px" }}>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>
      <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: { xs: "start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            sx={{
              color: "#110e24",
              fontSize: "20px",
              fontWeight: "500",
              textWrap: "nowrap",
              fontFamily: "Poppins",
            }}
          >Total Properties : {data?.totalProperties || 0}
          </Typography>
          <Box
            sx={{
              maxWidth: "350px",
              mt: { xs: 1, sm: "auto" },
              ml: { xs: "unset", sm: "auto" },
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                color: "white",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
                textTransform: "unset",
                boxShadow: "none !important"
              }}
              onClick={handleCreate}
              startIcon={<MuiIcons.AddRounded sx={{ fontSize: "18px" }} />}
            >Create property
            </Button>
          </Box>
        </Box>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {propertiesTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {property?.loadingAll ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(4)?.fill()?.map((_, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.properties?.map((property, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{i + 1}.
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{property?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{property?.requestCount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        py: 1,
                        whiteSpace: "nowrap"
                      }}
                    >
                      <IconButton
                        size="small"
                        sx={{ mr: 1 }}
                        LinkComponent={Link}
                        to={`/admin/property/${property?._id}`}
                      >
                        <MuiIcons.RemoveRedEyeOutlined
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => { handleDelete(property?._id) }}
                      >
                        <MuiIcons.DeleteOutlineOutlined
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => { handleCopyLink(property?._id) }}
                      >
                        <MuiIcons.ContentCopyRounded
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.properties?.length && !property?.loadingAll) &&
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          {!property?.loadingAll ?
            <Pagination
              as="div"
              color="primary"
              shape="rounded"
              boundaryCount={3}
              count={data?.pages?.totalPages}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { handleGetAll(n) }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    height: "30px",
                    width: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>
    </Fragment>
  )
}

export default AllProperties