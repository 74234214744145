import {
  YouTube,
  Twitter,
  LinkedIn,
  AddRounded,
  EditRounded,
  StarRounded,
  MenuRounded,
  ClearRounded,
  GroupRounded,
  ForumRounded,
  SearchRounded,
  DeleteRounded,
  FacebookRounded,
  FavoriteRounded,
  AddAPhotoRounded,
  DashboardRounded,
  AccessibleForward,
  UploadFileRounded,
  PlayArrowOutlined,
  DragHandleRounded,
  StarBorderOutlined,
  BorderColorRounded,
  CheckCircleRounded,
  ContentCopyRounded,
  MarkChatReadRounded,
  PriorityHighRounded,
  AnnouncementRounded,
  RemoveRedEyeRounded,
  AttachMoneyOutlined,
  VisibilityOffRounded,
  DeleteOutlineRounded,
  RemoveRedEyeOutlined,
  DeleteOutlineOutlined,
  HorizontalRuleRounded,
  MarkChatUnreadRounded,
  ArrowForwardIosRounded,
  MarkUnreadChatAltRounded,
  AdminPanelSettingsRounded,
  CheckCircleOutlineRounded,
} from "@mui/icons-material"

import { LuParkingSquare } from "react-icons/lu"
import { FaPoop, FaDog } from "react-icons/fa6"

const MuiIcons = {
  YouTube,
  Twitter,
  LinkedIn,
  AddRounded,
  EditRounded,
  StarRounded,
  MenuRounded,
  ClearRounded,
  GroupRounded,
  ForumRounded,
  SearchRounded,
  DeleteRounded,
  FacebookRounded,
  FavoriteRounded,
  AddAPhotoRounded,
  DashboardRounded,
  AccessibleForward,
  UploadFileRounded,
  PlayArrowOutlined,
  DragHandleRounded,
  StarBorderOutlined,
  BorderColorRounded,
  CheckCircleRounded,
  ContentCopyRounded,
  MarkChatReadRounded,
  PriorityHighRounded,
  AnnouncementRounded,
  RemoveRedEyeRounded,
  AttachMoneyOutlined,
  VisibilityOffRounded,
  DeleteOutlineRounded,
  RemoveRedEyeOutlined,
  DeleteOutlineOutlined,
  HorizontalRuleRounded,
  MarkChatUnreadRounded,
  ArrowForwardIosRounded,
  MarkUnreadChatAltRounded,
  AdminPanelSettingsRounded,
  CheckCircleOutlineRounded,
}

export const Icons = {
  FaDog,
  FaPoop,
  LuParkingSquare,
}


export default MuiIcons