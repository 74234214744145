import { getSingleUserReq, handleUpdateUserReq } from "../../../redux/adminSlices/users.slice"
import { propertiesTableHeader } from "../../../constant/admin/Tables"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import Header from "../../../components/admin/Header"
import { Link, useParams } from "react-router-dom"
import MuiIcons from "../../../assets/js/muiIcons"
import { grey } from "@mui/material/colors"
import moment from "moment"

import {
  Avatar, Box, Button, Chip, CircularProgress, Grid2 as Grid,
  IconButton, Pagination, Skeleton, Stack, styled, Switch, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material"
import { getAssignedPropertiesReq } from "../../../redux/adminSlices/property.slice"

const Span = styled("span")(() => ({ display: "inline-block" }))
function SingleUser() {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [data, setData] = useState({})
  const { property } = useSelector(state => state.propertySlice)
  const { singleUser: user } = useSelector(state => state.usersSlice)

  // Get single user
  const handleGetUser = () => {
    dispatch(getSingleUserReq(userId)
    )
  }

  // Handle update user (Active/Deactive)
  const handleUpdate = (e) => {
    dispatch(handleUpdateUserReq({
      id: userId,
      formData: { status: e?.target?.checked }
    })).unwrap().then((res) => {
      handleGetUser()
    })
  }

  // Get all properties
  const handleGetAll = (page = null, search = null) => {
    dispatch(getAssignedPropertiesReq({
      page,
      search,
      id: userId,
    })).unwrap().then((res) => {
      setData(res)
    }).catch(() => { })
  }

  useEffect(() => {
    handleGetUser()
    handleGetAll()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header />
      <Box>
        <Box sx={{ p: 3, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
          {!user?.loading ?
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Avatar
                  sx={{
                    color: "#00cada",
                    bgcolor: "#ceffff",
                    width: { xs: "75px", md: "150px" },
                    height: { xs: "75px", md: "150px" },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                    fontSize: { xs: "24px", md: "32px" },
                  }}
                  component="h2"
                >{user?.fullName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "16px", md: "18px" },
                  }}
                >{user?.email}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                >Status:&nbsp;
                  <Chip
                    sx={{
                      "span": {
                        color: "white",
                        lineHeight: "1",
                        fontSize: "12px",
                        overflow: "unset",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                      },
                      py: "2px",
                      height: "auto",
                      bgcolor: !user?.status ?
                        grey[500] : "#00cada"
                    }}
                    size="small"
                    component="span"
                    label={user?.status ? "active" : "deactive"}
                  />
                </Typography>
              </Grid>
              <Grid size={{ xs: "auto" }} sx={{ ml: "auto", alignSelf: "flex-start" }}>
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    boxShadow: "none !important"
                  }}
                  variant="contained"
                  LinkComponent={Link}
                  to={`/admin/edit/${userId}`}
                  startIcon={<MuiIcons.EditRounded />}
                >Edit profile
                </Button>
              </Grid>
            </Grid> :
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid size={{ xs: 12, sm: "auto" }}>
                <Skeleton
                  sx={{
                    width: { xs: "75px", md: "150px" },
                    height: { xs: "75px", md: "150px" },
                  }}
                  variant="circular"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: "auto" }} sx={{ flexGrow: "1 !important" }}>
                <Skeleton
                  sx={{
                    transform: "none",
                    width: { xs: "75%", md: "250px" },
                    height: { xs: "24px", md: "32px" },
                  }}
                />
                <Skeleton
                  sx={{
                    mt: 1,
                    transform: "none",
                    width: { md: "350px" },
                    height: { xs: "16px", md: "18px" },
                  }}
                />
              </Grid>
            </Grid>
          }

          {!user?.loading ?
            <Box sx={{ pt: 3 }}>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Name : &nbsp;</Span>
                <Span sx={{ fontWeight: "400" }}>{user?.fullName}</Span>
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Email : &nbsp;</Span>
                <Span sx={{ fontWeight: "400" }}>{user?.email}</Span>
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Role : &nbsp;</Span>
                <Span sx={{ fontWeight: "400" }}>{user?.role}</Span>
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Joined : &nbsp;</Span>
                <Span sx={{ fontWeight: "400" }}>{moment(user?.createdAt).format("DD/MM/YYYY")}</Span>
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Staus : &nbsp;</Span>
                <Span sx={{ fontWeight: "400" }}>
                  {!user?.sLoading ?
                    <Switch
                      sx={{
                        ".MuiButtonBase-root": {
                          color: "#00cada !important"
                        },
                        ".MuiSwitch-track": {
                          bgcolor: user?.status ? "#1ce7f4 !important" : ""
                        },
                      }}
                      checked={user?.status}
                      onChange={handleUpdate}
                    /> :
                    <CircularProgress
                      size={25}
                      thickness={6}
                      sx={{
                        color: "#00cada",
                        verticalAlign: "middle",
                        "span": { verticalAlign: "middle" }
                      }}
                    />
                  }
                </Span>
              </Typography>
            </Box> :
            <Box sx={{ pt: 3 }}>
              {new Array(5).fill().map((_, i) => (
                <Skeleton
                  sx={{
                    mt: 1,
                    transform: "none",
                    width: { xs: "100%", md: "75%" },
                    height: { xs: "24px", md: "28px" },
                  }}
                  key={i}
                />
              ))
              }
            </Box>
          }
        </Box>

        <Box sx={{ p: 3, pt: 0, width: { xs: "calc(100vw - 6px)", laptop: "calc(100vw - (250px + 6px))" } }}>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: { xs: "start", sm: "center" },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Typography
              sx={{
                color: "#110e24",
                fontSize: "20px",
                fontWeight: "500",
                textWrap: "nowrap",
                fontFamily: "Poppins",
              }}
            >Found properties : {data?.totalProperties || 0}
            </Typography>
            <Box
              sx={{
                maxWidth: "350px",
                mt: { xs: 1, sm: "auto" },
                ml: { xs: "unset", sm: "auto" },
              }}
            >
            </Box>
          </Box>

          <TableContainer
            sx={{
              maxWidth: "100%",
              borderRadius: "8px",
              boxShadow: "0px 0px 20px 0px #4545452e"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {propertiesTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={{
                        py: 1,
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "500",
                        minWidth: "100px",
                        bgcolor: "#00cada",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                      }}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {property?.loadingAll ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(4)?.fill()?.map((_, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.properties?.map((property, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        sx={{
                          py: 1,
                          fontSize: "15px",
                          minWidth: "100px",
                          maxWidth: "250px",
                          textWrap: "nowrap",
                          overflow: "hidden",
                          fontFamily: "Poppins",
                          textOverflow: "ellipsis",
                          textTransform: "capitalize",
                        }}
                        align="left"
                      >{i + 1}.
                      </TableCell>
                      <TableCell
                        sx={{
                          py: 1,
                          fontSize: "15px",
                          minWidth: "100px",
                          maxWidth: "250px",
                          textWrap: "nowrap",
                          overflow: "hidden",
                          fontFamily: "Poppins",
                          textOverflow: "ellipsis",
                        }}
                        align="left"
                      >{property?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          py: 1,
                          fontSize: "15px",
                          minWidth: "100px",
                          maxWidth: "250px",
                          textWrap: "nowrap",
                          overflow: "hidden",
                          fontFamily: "Poppins",
                          textOverflow: "ellipsis",
                        }}
                        align="left"
                      >{property?.requestCount}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          py: 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        <IconButton
                          size="small"
                          sx={{ mr: 1 }}
                          LinkComponent={Link}
                          to={`/admin/property/${property?._id}`}
                        >
                          <MuiIcons.RemoveRedEyeOutlined
                            sx={{ fontSize: "20px", color: "#00cada" }} />
                        </IconButton>
                        {/* <IconButton
                        size="small"
                        onClick={() => { handleDelete(property?._id) }}
                      >
                        <MuiIcons.DeleteOutlineOutlined
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => { handleCopyLink(property?._id) }}
                      >
                        <MuiIcons.ContentCopyRounded
                          sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton> */}
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.properties?.length && !property?.loadingAll) &&
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 3 }}>
            {!property?.loadingAll ?
              <Pagination
                as="div"
                color="primary"
                shape="rounded"
                boundaryCount={3}
                count={data?.pages?.totalPages}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { handleGetAll(n) }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      height: "30px",
                      width: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default SingleUser