import { setNewPasswordReq } from "../../redux/userSlices/auth.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import CustomInput from "../../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  Box, Button, CircularProgress,
  Container, Grid2 as Grid,
  Paper, styled,
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
function SetNewPassword() {
  const { userId } = useParams()
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state.authSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.userId = userId
    if ([formData?.password, formData?.confPassword]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Password is required!"))
    }
    if (formData?.password !== formData?.confPassword) {
      return dispatch(toastError("Password not matched!"))
    }
    const capsRegex = /[A-Z]/
    const smallRegex = /[a-z]/
    const specialRegex = /[!@#$%^&*?]/
    const numberRegex = /[0-9]/
    if (!capsRegex.test(formData?.password)) {
      return dispatch(toastError("Password must include at least one capital letter."))
    }
    if (!smallRegex.test(formData?.password)) {
      return dispatch(toastError("Password must include at least one small letter."))
    }
    if (!specialRegex.test(formData?.password)) {
      return dispatch(toastError("Password must include a specail characters."))
    }
    if (!numberRegex.test(formData?.password)) {
      return dispatch(toastError("Password must include a number."))
    }
    if (formData?.password?.length < 8) {
      return dispatch(toastError("Password must be at least 8 characters long."))
    }

    dispatch(setNewPasswordReq(formData))
  }

  return (
    <Box component="section" sx={{ bgcolor: "#f0f9ff", height: "100%" }}>
      <Container sx={{ height: "100%" }}>
        <Box sx={{ display: "grid", placeContent: "center", height: "100%", py: 4 }}>
          <Box
            sx={{
              p: 3,
              maxWidth: "450px",
              borderRadius: "8px",
              boxShadow: "0px 0px 12px 0px #00000024"
            }}
            component={Paper}
          >
            <Paragraph
              sx={{
                mb: 2,
                color: "#110e24",
                fontSize: "35px",
                fontWeight: "600",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >Set new password
            </Paragraph>
            <Paragraph
              sx={{
                mb: 1,
                color: "#110e24",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Please enter the new password.
            </Paragraph>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid size={12}>
                  <CustomInput
                    name="password"
                    type="password"
                    label="password"
                  />
                </Grid>
                <Grid size={12}>
                  <CustomInput
                    name="confPassword"
                    type="password"
                    label="confrim password"
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    sx={{
                      width: "100%",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      boxShadow: "none !important"
                    }}
                    type="submit"
                    variant="contained"
                  >Submit
                    {auth?.loadingAuth &&
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{
                          ml: 1,
                          display: "flex",
                          color: "white"
                        }}
                      />
                    }
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default SetNewPassword