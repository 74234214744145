import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import router from "../../routes/routes"
import { Cookies } from "react-cookie"
import axios from "axios"

const cookies = new Cookies()

// Admin login
export const adminLoginReq = createAsyncThunk("adminLoginReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/admin/login`, data)

    if (response.data?.success) {
      dispatch(toastSuccess(response.data?.message))
      cookies.set("adminToken", response.data.token, { path: "/" })
      return response.data?.user
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle create sub admin
export const inviteSubAdminReq = createAsyncThunk("inviteSubAdminReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/admin/sub-admin/invitation`, data, {
      headers: adminHeaders()
    })

    if (response.data?.success) {
      dispatch(toastSuccess(response.data?.message))
      return response.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle create sub admin
export const editAssignedPropsReq = createAsyncThunk("inviteSubAdminReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/admin/sub-admin/assign-properties/${data?.id}`, data?.formData, {
      headers: adminHeaders()
    })

    if (response.data?.success) {
      dispatch(toastSuccess(response.data?.message))
      return response.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle set new password
export const setNewPasswordReq = createAsyncThunk("inviteSubAdminReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/admin/sub-admin/set-password`, data, {
      headers: adminHeaders()
    })

    if (response.data?.success) {
      dispatch(toastSuccess(response.data?.message))
      router.navigate("/admin/login")
      return response.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Get dashboard data
export const dashboardDataReq = createAsyncThunk("getAllAdminsReq", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${BASEURI}/admin/dashboard`, {
      headers: adminHeaders()
    })

    if (response.data?.success) {
      return response.data?.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Get all admins
export const getAllAdminsReq = createAsyncThunk("getAllAdminsReq", async (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = await axios.get(`${BASEURI}/admin/sub-admin/get-all`, {
      params,
      headers: adminHeaders()
    })

    if (response.data?.success) {
      return response.data?.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

const initialState = {
  admin: {},
  allAdmin: {},
}
const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Admin login
    builder.addCase(adminLoginReq.pending, (state) => {
      state.admin.loading = true
    })
    builder.addCase(adminLoginReq.fulfilled, (state, { payload }) => {
      delete state?.admin?.loading
      state.admin = payload || {}
    })
    builder.addCase(adminLoginReq.rejected, (state) => {
      delete state?.admin?.loading
    })

    // Get all admins
    builder.addCase(getAllAdminsReq.pending, (state) => {
      state.allAdmin.loadingAll = true
    })
    builder.addCase(getAllAdminsReq.fulfilled, (state) => {
      delete state?.allAdmin?.loadingAll
    })
    builder.addCase(getAllAdminsReq.rejected, (state) => {
      delete state?.allAdmin?.loadingAll
    })

    // Create admin
    builder.addCase(inviteSubAdminReq.pending, (state) => {
      state.allAdmin.loadingCreate = true
    })
    builder.addCase(inviteSubAdminReq.fulfilled, (state, { payload }) => {
      delete state?.allAdmin?.loadingCreate
    })
    builder.addCase(inviteSubAdminReq.rejected, (state) => {
      delete state?.allAdmin?.loadingCreate
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default admin.reducer