import { hideDialog, refresher, toastError } from "../../../redux/guestSlices/other.slice"
import { getAllPropertiesReq } from "../../../redux/adminSlices/property.slice"
import { inviteSubAdminReq } from "../../../redux/adminSlices/admin.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import { useEffect, useState } from "react"
import CustomInput from "../CustomInput"

import {
  Autocomplete, Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Grid2 as Grid, IconButton,
  InputLabel, Paper, Skeleton, TextField, Typography
} from "@mui/material"

function CreateAdmin() {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { allAdmin } = useSelector(state => state.adminSlice)
  const { property } = useSelector(state => state.propertySlice)
  let properties = [];

  // Hide dialog
  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle create property
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    const propIds = []
    properties.forEach(({ _id }) => { propIds.push(_id) })
    formData.propIds = propIds
    if ([formData?.fullName, formData?.email].some(
      (field) => field?.trim() === "" || !field
    )) {
      return dispatch(toastError("All fields are required!"))
    }
    dispatch(inviteSubAdminReq(formData)
    ).unwrap().then((res) => {
      if (res) {
        setTimeout(() => {
          handleClose()
          dispatch(refresher())
        }, 500)
      }
    }).catch(() => { })
  }

  // Handle name validation
  const handleOnChange = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, '')
  }

  const handleChange = (_, value) => {
    properties = value
  }

  // Get all properties
  const handleGetAll = (page = null, search = null) => {
    dispatch(getAllPropertiesReq({
      page,
      search
    })).unwrap().then((res) => {
      setData(res?.properties)
    }).catch(() => { })
  }

  useEffect(() => {
    handleGetAll()

    // eslint-disable-next-line
  }, [])

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle component="div" sx={{ position: "relative" }}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Montserrat",
            color: "secondary.main",
          }}
          component="h6"
        >Create sub admin
        </Typography>
        <IconButton
          sx={{
            top: "50%",
            right: "10px",
            position: "absolute",
            transform: "translateY(-50%)"
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded sx={{ fontSize: "18px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="relative">
        <Grid container spacing={2}>
          <Grid size={12}>
            <CustomInput
              label="name"
              name="fullName"
              onChngFun={handleOnChange}
            />
          </Grid>
          <Grid size={12}>
            <CustomInput
              name="email"
              type="email"
              label="email"
            />
          </Grid>
          <Grid size={12}>
            <InputLabel
              sx={{
                mb: 0.5,
                color: "#110e24",
                display: "block",
                textAlign: "start",
                fontFamily: "Poppins",
                textTransform: "capitalize",
              }}
              htmlFor="abced"
            >Select property
            </InputLabel>
            {!property?.loadingAll ?
              <Autocomplete
                multiple
                fullWidth
                size="small"
                id="abced"
                name="testt"
                options={data}
                getOptionLabel={(option) => option?.name || ""}
                getOptionKey={(option) => option?._id}
                // value={episodeVal || []}
                // sx={{ width: '500px' }}
                // onInputChange={handleChange}
                renderInput={(params) => (
                  <TextField name="test" {...params} />
                )}
                onChange={handleChange}
              /> :
              <Skeleton
                sx={{
                  height: "40px",
                  transform: "none",
                  borderRadius: "4px",
                }}
              />
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
          }}
          type="button"
          variant="outlined"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          sx={{
            color: "white",
            minWidth: "100px",
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
            boxShadow: "none !important",
          }}
          endIcon={allAdmin?.loadingCreate &&
            <CircularProgress
              size={22}
              thickness={5}
              sx={{
                color: allAdmin?.loadingCreate ?
                  "white" : "#00000042"
              }}
            />
          }
          type="submit"
          disabled={allAdmin?.loadingCreate}
          variant="contained"
        >Create
        </Button>
      </DialogActions>
    </Paper >
  )
}

export default CreateAdmin