import { hideDialog, showDialog, toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI } from "../../utils/helper"
import router from "../../routes/routes"
import { Cookies } from "react-cookie"
import axios from "axios"

const cookies = new Cookies()

// User authentication request
export const userAuthenticationReq = createAsyncThunk("userAuthenticationReq", async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${BASEURI}/user/authentication/${id}`)

    if (response.data?.success) {
      return response?.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Property authentication request
export const propAuthenticationReq = createAsyncThunk("userAuthenticationReq", async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${BASEURI}/user/property/authentication/${id}`)

    if (response.data?.success) {
      return response?.data
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Set new password request
export const setNewPasswordReq = createAsyncThunk("userAuthenticationReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/user/set-password`, data,)

    if (response.data?.success) {
      dispatch(toastError(response.data?.error))
      if (response.data?.user?.role === "user") {
        cookies.set("token", response.data.token, { path: "/" })
        dispatch(toastSuccess(response.data?.message))
        router?.navigate("/user/upload")
        return response.data?.user
      }
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
      if (response.data?.status === "email") {
        dispatch(showDialog("OtpDialog"))
        return { email: response.data?.email }
      }
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle login request
export const userLoginReq = createAsyncThunk("userLoginReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/user/login`, data)

    if (response.data?.success) {
      dispatch(toastError(response.data?.error))
      if (response.data?.user?.role === "user") {
        cookies.set("token", response.data.token, { path: "/" })
        dispatch(toastSuccess(response.data?.message))
        router?.navigate("/user/upload")
        return response.data?.user
      }
    } else {
      const newErr = response.data?.error || "Something went wrong here!"
      dispatch(toastError(newErr))
      if (response.data?.status === "email") {
        dispatch(showDialog("OtpDialog"))
        return { email: response.data?.email }
      }
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle signup request
export const userSignupReq = createAsyncThunk("userLoginReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASEURI}/user/register`, data)

    if (!response.data?.success) {
      dispatch(toastError(response.data?.error))
      if (response.data?.status === "email") {
        return { email: response?.data?.email }
      }
    }

    return response
  } catch (error) {
    const newErr = error?.response?.data?.error || "Something went wrong!"
    dispatch(toastError(newErr))
    return rejectWithValue(error)
  }
})

// Handle varify email request
export const userVarifyEmailReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/varify-email`, data,
    ).then((res) => {
      dispatch(hideDialog())
      if (res.data?.success) {
        cookies.set("token", res.data.token, { path: "/" })
        dispatch(toastSuccess(res.data?.message))
        router?.navigate("/user/upload")
        return res.data?.user
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle forgot password request
export const forgotPasswordReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/forgot-password`, data,
    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        return {}
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle reset password request
export const resetPasswordReq = createAsyncThunk("userLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/reset-password`, data,
    ).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        router.navigate("/")
        return {}
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  auth: {},
  user: {}
}
const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Authentication request
    builder.addCase(userAuthenticationReq.pending, (state) => {
      state.auth.loadingAuth = true
    })
    builder.addCase(userAuthenticationReq.fulfilled, (state) => {
      delete state?.auth?.loadingAuth
    })
    builder.addCase(userAuthenticationReq.rejected, (state) => {
      delete state?.auth?.loadingAuth
    })

    // Handle login and signup request
    builder.addCase(userLoginReq.pending, (state) => {
      state.user.loading = true
    })
    builder.addCase(userLoginReq.fulfilled, (state, { payload }) => {
      delete state.user.loading
      state.user = payload || {}
    })
    builder.addCase(userLoginReq.rejected, (state) => {
      delete state.user.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default auth.reducer