import { propAuthenticationReq } from "../../redux/userSlices/auth.slice"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Box, CircularProgress } from "@mui/material"
import { useEffect } from "react"
import Signup from "./Signup"

function PropAuth() {
  const { propId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { auth } = useSelector(state => state.authSlice)

  // Check property link expiry
  const handleAuthentication = () => {
    dispatch(propAuthenticationReq(propId)
    ).unwrap().catch(() => {
      setTimeout(() => {
        return navigate("/")
      }, 200)
    })
  }

  useEffect(() => {
    handleAuthentication()

    // eslint-disable-next-line
  }, [])

  return (
    auth?.loadingAuth ?
      <Box sx={{ display: "grid", placeContent: "center", height: "100%" }}>
        <CircularProgress
          size={70}
          thickness={6}
        />
      </Box> :
      <Signup />
  )
}

export default PropAuth