import { hideDialog, refresher, toastError } from "../../../redux/guestSlices/other.slice"
import { createPropertyReq } from "../../../redux/adminSlices/property.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomInput from "../CustomInput"

import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, IconButton,
  Paper, Typography
} from "@mui/material"

function CreateProperty() {
  const dispatch = useDispatch()
  const { property } = useSelector(state => state.propertySlice)

  // Close dialog
  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle create property
  const handleCreate = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (formData?.name?.trim() === "") {
      return dispatch(toastError("Name is required!"))
    }
    dispatch(createPropertyReq(formData)
    ).unwrap().then(() => {
      setTimeout(() => {
        handleClose()
        dispatch(refresher())
      }, 500)
    }).catch(() => { })
  }

  return (
    <Paper component="form" onSubmit={handleCreate}>
      <DialogTitle component="div" sx={{ position: "relative" }}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Montserrat",
            color: "secondary.main",
          }}
          component="h6"
        >Add property
        </Typography>
        <IconButton
          sx={{
            top: "50%",
            right: "10px",
            position: "absolute",
            transform: "translateY(-50%)"
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded sx={{ fontSize: "18px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="relative">
        <CustomInput
          name="name"
          label="name"
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
          }}
          type="button"
          variant="outlined"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          sx={{
            color: "white",
            minWidth: "100px",
            fontWeight: "400",
            fontFamily: "Poppins",
            textTransform: "unset",
            boxShadow: "none !important",
          }}
          endIcon={property?.loadingCreate &&
            <CircularProgress
              size={22}
              thickness={5}
              sx={{
                color: property?.loadingCreate ?
                  "white" : "#00000042"
              }}
            />
          }
          type="submit"
          disabled={property?.loadingCreate}
          variant="contained"
        >Create
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default CreateProperty