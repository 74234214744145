import SingleProperty from "../pages/admin/properties/SingleProperty"
import AllProperties from "../pages/admin/properties/AllProperties"
import AdminProtected from "../components/protected/AdminProtected"
import SingleRequest from "../pages/admin/requests/SingleRequest"
import SingleContact from "../pages/admin/contacts/SingleContact"
import AdminProfile from "../pages/admin/profiles/AdminProfile"
import EditProfile from "../pages/admin/profiles/EditProfile"
import AllContact from "../pages/admin/contacts/AllContact"
import AllAdmins from "../pages/admin/sub-admins/AllAdmins"
import AllRequest from "../pages/admin/requests/AllRequest"
import SingleUser from "../pages/admin/users/SingleUser"
import Profile from "../pages/admin/profiles/Profile"
import AllUser from "../pages/admin/users/AllUser"
import Dashboard from "../pages/admin/Dashboard"
import Layout from "../components/admin/Layout"
import { Route } from "react-router-dom"
import Login from "../pages/admin/Login"

const AdminRoutes = (
  <Route path="/admin" element={<Layout />}>
    <Route path="login" element={<Login />} />
    <Route path="all-users" element={<AdminProtected><AllUser /></AdminProtected>} />
    <Route path="dashboard" element={<AdminProtected><Dashboard /></AdminProtected>} />
    <Route path="sub-admins" element={<AdminProtected><AllAdmins /></AdminProtected>} />
    <Route path="profile" element={<AdminProtected><AdminProfile /></AdminProtected>} />
    <Route path="all-request" element={<AdminProtected><AllRequest /></AdminProtected>} />
    <Route path="profile/:userId" element={<AdminProtected><Profile /></AdminProtected>} />
    <Route path="all-contacts" element={<AdminProtected><AllContact /></AdminProtected>} />
    <Route path="user/:userId" element={<AdminProtected><SingleUser /></AdminProtected>} />
    <Route path="edit/:userId" element={<AdminProtected><EditProfile /></AdminProtected>} />
    <Route path="properties" element={<AdminProtected><AllProperties /></AdminProtected>} />
    <Route path="property/:id" element={<AdminProtected><SingleProperty /></AdminProtected>} />
    <Route path="request/:requestId" element={<AdminProtected><SingleRequest /></AdminProtected>} />
    <Route path="contact/:requestId" element={<AdminProtected><SingleContact /></AdminProtected>} />
  </Route>
)

export default AdminRoutes