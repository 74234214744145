import { Box, Button, Collapse, Container, IconButton, Link, Stack, styled } from "@mui/material"
import { Link as NavLink, useLocation, useNavigate } from "react-router-dom"
import menus from "../../constant/guest/Header"
import { checkToken } from "../../utils/helper"
import MuiIcons from "../../assets/js/muiIcons"
import { useDispatch } from "react-redux"
import { Cookies } from "react-cookie"
import { useState } from "react"
import Images from "../../assets/images"

const Image = styled("img")(() => ({}))

function Header() {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isLogin = checkToken(dispatch)
  const [active, setActive] = useState(false)

  const handleLogout = () => {
    cookies.remove("token", { path: "/" })
    dispatch({ type: "Logout", payload: "" })
    navigate("/login")
  }

  return (
    <Box
      sx={{
        py: 2,
        backdropFilter: "blur(4px)",
        boxShadow: "0px 0px 15px 0px #78787847",
      }}
      component="header"
    >
      <Container maxWidth="xxl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: 1, md: 2, laptop: 6 },
          }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Link
              to="/"
              component={NavLink}
              sx={{ display: "block" }}
            >
              <Image
                sx={{
                  width: "100%",
                  height: "50px",
                  objectFit: "contain",
                  objectPosition: "left",
                }}
                alt="logo"
                src={Images?.logo}
              />
            </Link>
          </Box>

          <Stack
            spacing={3}
            component="nav"
            direction="row"
            sx={{ ml: "auto", display: { xs: "none", laptop: "flex" } }}
          >
            {menus?.map((menu, i) => (
              <Link
                sx={{
                  mr: "auto",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  textTransform: "capitalize",
                  transitionDuration: "350ms",
                  color: pathname === menu?.path ? "#00cada" : "#110e24",
                  ":hover": pathname !== menu?.path && { color: "#00cada" }
                }}
                key={i}
                to={menu?.path}
                component={NavLink}
              >{menu?.name}
              </Link>
            ))
            }

            {!isLogin ?
              <Link
                sx={{
                  mr: "auto",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  textTransform: "capitalize",
                  transitionDuration: "350ms",
                  color: pathname === "/login" ? "#00cada" : "#110e24",
                  ":hover": pathname !== "/login" && { color: "#00cada" }
                }}
                to="/login"
                component={NavLink}
              >login
              </Link> :
              <Link
                sx={{
                  mr: "auto",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  textTransform: "capitalize",
                  transitionDuration: "350ms",
                  color: pathname === "/login" ? "#00cada" : "#110e24",
                  ":hover": pathname !== "/login" && { color: "#00cada" }
                }}
                to="/user/upload"
                component={NavLink}
              >upload
              </Link>
            }
          </Stack>

          <Box sx={{ ml: { xs: "auto", laptop: "0", }, display: { xs: "none", laptop: "flex" }, gap: 2 }}>
            {!isLogin ?
              <Button
                sx={{
                  color: "white",
                  fontWeight: "400",
                  textWrap: "nowrap",
                  boxShadow: "none",
                  bgcolor: "#00cada",
                  borderRadius: "30px",
                  fontFamily: "Poppins",
                  textTransform: "unset",
                  textDecoration: "none",
                  transition: "all ease 350ms",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 0px 15px 0px #00000038",
                  }
                }}
                to="/support"
                variant="contained"
                LinkComponent={NavLink}
              >Book a Demo
              </Button> :
              <Button
                sx={{
                  color: "white",
                  fontWeight: "500",
                  boxShadow: "none",
                  bgcolor: "#00cada",
                  borderRadius: "30px",
                  fontFamily: "Poppins",
                  textTransform: "capitalize"
                }}
                variant="contained"
                onClick={handleLogout}
              >Logout
              </Button>
            }
          </Box>
          <IconButton
            sx={{
              ml: "auto",
              color: "white",
              bgcolor: "#00cada !important",
              display: { xs: "flex", laptop: "none" }
            }}
            size="small"
            onClick={() => { setActive(!active) }}
          >
            {!active ?
              <MuiIcons.MenuRounded /> :
              <MuiIcons.ClearRounded />
            }
          </IconButton>
        </Box>

        <Collapse
          in={active}
          sx={{ display: { xs: "block", laptop: "none" } }}
        >
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            {menus?.map((menu, i) => (
              <Link
                sx={{
                  px: 2,
                  py: "4px",
                  minWidth: "250px",
                  textAlign: "center",
                  borderRadius: "20px",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  transitionDuration: "350ms",
                  color: pathname === menu?.path ? "#00cada" : "#110e24",
                  textTransform: menu?.name === "faq" ? "uppercase" : "capitalize",
                  ":hover": pathname !== menu?.path && { color: "#00cada" }
                }}
                key={i}
                to={menu?.path}
                component={NavLink}
                onClick={() => { setActive(false) }}
              >{menu?.name}
              </Link>
            ))
            }
            {!isLogin ?
              <Link
                sx={{
                  px: 2,
                  py: "4px",
                  minWidth: "250px",
                  textAlign: "center",
                  borderRadius: "20px",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  transitionDuration: "350ms",
                  textTransform: "capitalize",
                  color: pathname === "/login" ? "#00cada" : "#110e24",
                  ":hover": pathname !== "/login" && { color: "#00cada" }
                }}
                to="/login"
                component={NavLink}
                onClick={() => { setActive(false) }}
              >login
              </Link> :
              <Link
                sx={{
                  px: 2,
                  py: "4px",
                  minWidth: "250px",
                  textAlign: "center",
                  borderRadius: "20px",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  transitionDuration: "350ms",
                  textTransform: "capitalize",
                  color: pathname === "/login" ? "#00cada" : "#110e24",
                  ":hover": pathname !== "/login" && { color: "#00cada" }
                }}
                to="/user/upload"
                component={NavLink}
                onClick={() => { setActive(false) }}
              >upload
              </Link>
            }
            {!isLogin ?
              <Button
                sx={{
                  color: "white",
                  fontWeight: "400",
                  textWrap: "nowrap",
                  boxShadow: "none",
                  bgcolor: "#00cada",
                  borderRadius: "30px",
                  fontFamily: "Poppins",
                  textTransform: "unset",
                  textDecoration: "none",
                  transition: "all ease 350ms",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 0px 15px 0px #00000038",
                  }
                }}
                to="/support"
                variant="contained"
                LinkComponent={NavLink}
                onClick={() => { setActive(false) }}
              >Book a Demo
              </Button> :
              <Button
                sx={{
                  color: "white",
                  fontWeight: "500",
                  boxShadow: "none",
                  bgcolor: "#00cada",
                  borderRadius: "30px",
                  fontFamily: "Poppins",
                  textTransform: "capitalize"
                }}
                variant="contained"
                onClick={handleLogout}
              >Logout
              </Button>
            }
          </Stack>
        </Collapse>
      </Container>
    </Box>
  )
}

export default Header