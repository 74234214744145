import MuiIcons from "../../assets/js/muiIcons";

export const data = [
  {
    title: "admin",
    numbers: "totalAdmins",
    path: "/admin/sub-admins",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "total users",
    numbers: "totalUsers",
    path: "/admin/all-users",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "total properties",
    numbers: "totalProperties",
    path: "/admin/properties",
    icon: <MuiIcons.ForumRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "total request",
    numbers: "totalRequest",
    path: "/admin/all-request",
    icon: <MuiIcons.ForumRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "pending request",
    numbers: "pendingRequest",
    path: "/admin/all-request?status=pending",
    icon: <MuiIcons.MarkChatUnreadRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "approved request",
    numbers: "approvedRequest",
    path: "/admin/all-request?status=approved",
    icon: <MuiIcons.MarkChatReadRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "rejected request",
    numbers: "rejectedRequest",
    path: "/admin/all-request?status=rejected",
    icon: <MuiIcons.AnnouncementRounded sx={{ fontSize: "32px" }} />
  },
]