import { userLoginReq } from "../../redux/userSlices/auth.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import CustomInput from "../../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { checkToken } from "../../utils/helper"
import { useEffect } from "react"

import {
  Box, Button, CircularProgress, Container, Grid2 as Grid,
  Link as NavLink, Paper, styled,
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLogin = checkToken(dispatch)
  const { user } = useSelector(state => state.authSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if ([formData?.email, formData?.password]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Please fill all required field!"))
    }
    dispatch(userLoginReq(formData))
  }

  useEffect(() => {
    if (isLogin) { return navigate("/") }

    // eslint-disable-next-line
  }, [])

  return (
    <Box component="section" sx={{ bgcolor: "#f0f9ff", height: "100%" }}>
      <Container sx={{ height: "100%" }}>
        <Box sx={{ display: "grid", placeContent: "center", height: "100%", py: 4 }}>
          <Box
            sx={{
              p: 3,
              maxWidth: "450px",
              borderRadius: "8px",
              boxShadow: "0px 0px 12px 0px #00000024"
            }}
            component={Paper}
          >
            <Paragraph
              sx={{
                mb: 2,
                color: "#110e24",
                fontSize: "35px",
                fontWeight: "600",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >Login
            </Paragraph>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <CustomInput
                    name="email"
                    type="email"
                    label="email"
                  />
                </Grid>
                <Grid size={12}>
                  <CustomInput
                    name="password"
                    type="password"
                    label="password"
                  />
                  <Paragraph sx={{ textAlign: "right" }}>
                    <NavLink
                      sx={{
                        fontSize: "13px",
                        color: "#110e24",
                        fontWeight: "400",
                        textDecoration: "none",
                        transitionDuration: "350ms",
                        ":hover": { color: "#00cada" }
                      }}
                      component={Link}
                      to="/forgot-password"
                    >Forgot password?
                    </NavLink>
                  </Paragraph>
                </Grid>
                <Grid size={12}>
                  <Button
                    sx={{
                      width: "100%",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      boxShadow: "none !important",
                    }}
                    type="submit"
                    variant="contained"
                  >Login
                    {user?.loading &&
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{
                          ml: 1,
                          display: "flex",
                          color: "white"
                        }}
                      />
                    }
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Login